<template>
  <div class="md:h-full md:flex md:flex-col md:overflow-y-hidden">
    <div
      class="page-content__title bg-white py-4 px-4 sm:px-6 text-left shadow z-10"
    >
      <div class="flex items-center">
        <div class="page-content__icon pr-2">
          <slot name="icon"></slot>
        </div>
        <h1 class="text-xl md:text-2xl font-semibold text-gray-800 flex-1">
          <p v-if="subtitle" class="text-sm font-bold">{{ subtitle }}</p>
          {{ title }}
        </h1>
        <div class="page-content__actions pl-2 flex">
          <slot name="actions"></slot>
        </div>
      </div>

      <snap-link
        v-if="backRoute"
        :text="backRoute.text ?? 'Back'"
        icon="arrow-left-solid"
        @click="$router.push(backRoute.route)"
        sr-only="srOnly"
      ></snap-link>

      <NavigationTabs
        class="mt-2 -ml-4 -mb-6 pb-px w-full"
        type="primary"
        :routes="routes"
        @tab:selected="$emit('tab:selected', $event)"
      ></NavigationTabs>
    </div>

    <div class="md:px-6 md:flex-1 md:overflow-y-auto">
      <div class="mb-24">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import NavigationTabs from "@/components/snap-ui/NavigationTabs.vue";
import { NavigationTabMenuItem } from "@/types/ui";
import { BackRoute } from "@/types/ui";
import { PropType } from "vue";

@Options({
  props: {
    title: String,
    subtitle: String,
    routes: Array<NavigationTabMenuItem>,
    backRoute: Object as PropType<BackRoute>,
  },
  components: { NavigationTabs },
  emits: ["tab:selected"],
})
export default class PageContent extends Vue {
  title?: string;
  subtitle?: string;
  routes?: Array<NavigationTabMenuItem>;
  backRoute?: BackRoute;
}
</script>

<style scoped lang="scss">
.page-content__header:empty,
.page-content__footer:empty,
.page-content__icon:empty,
.page-content__actions:empty {
  display: none;
}

.page-content__actions {
  :slotted(snap-button + snap-button) {
    margin-left: 0.5rem;
  }
}

.page-content__title {
  @media (min-width: 768px) and (max-width: 992px) {
    left: 92px;
  }

  @media (min-width: 992px) {
    left: 286px;
  }
}
</style>
