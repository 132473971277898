<template>
  <component
    :is="wrapper"
    v-show="isAuthorized"
    class="min-h-screen relative"
    style="background: #1269c1"
    @snap-impersonation-mode-changed="onImpersonationModeChange"
  >
    <div class="absolute inset-y-6 inset-x-0 sm:inset-6 flex">
      <div class="bg-white w-full flex flex-col overflow-hidden sm:rounded-lg">
        <div>
          <div
            class="w-full border-b border-gray-200 h-16 flex items-center justify-between px-6"
          >
            <snap-logo
              product="snap-logo-mobile"
              white="false"
              class="cursor-pointer"
              @click.prevent="$router.push({ name: 'home' })"
            ></snap-logo>

            <snap-button
              size="sm"
              icon="logout-solid"
              variant="tertiary"
              icon-position="undefined"
              disabled="false"
              on-submit-btn="false"
              icon-only-mobile="false"
              @click="exitButtonClick"
            >
              Exit
            </snap-button>
          </div>
        </div>
        <div class="flex-1 overflow-auto">
          <div
            class="w-full max-w-4xl mx-auto px-2 py-6 flex items-start sm:items-center justify-center min-h-full"
          >
            <div class="w-full">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>

  <ZendeskHelpWidget></ZendeskHelpWidget>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { Options, Vue } from "vue-class-component";
import { MainNavigationUpdatedEvent } from "@snap-mobile/snap-ui/dist/types/utils";

import PageHeaderNav from "@/components/internal/common/PageHeaderNav.vue";
import SelfIdentifyDialog from "@/components/internal/challenges/SelfIdentifyDialog.vue";
import ZendeskHelpWidget from "@/components/ZendeskHelpWidget.vue";

import { FeatureToggle } from "@/store/treatments.module";
import { MultiplePermissionsChecker } from "@/store/user.module";

@Options({
  components: { ZendeskHelpWidget, SelfIdentifyDialog, PageHeaderNav },
  computed: {
    ...mapGetters([
      "theme",
      "isAuthorized",
      "isSpoofed",
      "featureOn",
      "hasEveryPermissions",
    ]),
  },
})
export default class BasicPage extends Vue {
  orgId?: string;
  theme?: string;
  isAuthorized?: boolean;
  isSpoofed!: boolean;
  featureOn?: FeatureToggle;
  hasEveryPermissions?: MultiplePermissionsChecker;

  get wrapper(): string {
    return this.isImpersonated ? "snap-impersonation-mode" : "div";
  }

  get isImpersonated(): boolean {
    return this.featureOn?.("USER-801-use-snap-impersonation-mode")
      ? this.isSpoofed
      : false;
  }

  async beforeMount() {
    await this.$store.dispatch("useTreatments");
  }

  navigate(ev: CustomEvent<MainNavigationUpdatedEvent>) {
    if (ev.detail.id) {
      this.$router.push({ name: ev.detail.id });
    }
  }

  switchContext(ev: CustomEvent) {
    if (ev.detail.id) {
      this.$router.push({ name: ev.detail.id });
    }
  }

  onImpersonationModeChange(event: CustomEvent) {
    if (event.detail !== this.isSpoofed) location.reload();
  }

  exitButtonClick() {
    this.$router.push({
      name: "org/rosters",
      params: { orgId: this.orgId },
    });
  }
}
</script>

<style scoped lang="scss">
:deep(.snap-impersonation-mode) {
  @media (max-width: 768px) {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

main {
  @media (max-width: 768px) {
    padding-top: 48px;
    padding-bottom: 61px;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    padding-top: 56px;
    padding-left: 92px;
  }

  @media (min-width: 992px) {
    padding-top: 56px;
    padding-left: 286px;
  }
}
</style>
