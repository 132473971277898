import { RouteRecordRaw } from "vue-router";

import InternalPage from "@/layouts/InternalPage.vue";
import Family from "@/views/internal/Family.vue";
import { PageContext } from "@/types/ui";

export const ProfileRoutes: Array<RouteRecordRaw> = [
  {
    path: "/profile/view",
    redirect: "/",
  },

  {
    path: "/profile/affiliations",
    name: "profile/affiliations",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "@/views/profile/ProfileOrgs.vue"
      ),

    meta: {
      title: "Affiliations",
      requiresAuth: true,
      layout: InternalPage,
      context: PageContext.User,
    },
  },

  {
    path: "/children",
    redirect: "/family",
  },

  {
    path: "/family",
    name: "family",
    component: Family,

    meta: {
      title: "Guardian Portal",
      requiresAuth: true,
      hasAnyPermissions: ["computed:has_family", "computed:parent"],
      layout: InternalPage,
      context: PageContext.User,
    },
  },
];
