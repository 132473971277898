import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c8a328c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "absolute inset-y-6 inset-x-0 sm:inset-6 flex" }
const _hoisted_2 = { class: "bg-white w-full flex flex-col overflow-hidden sm:rounded-lg" }
const _hoisted_3 = { class: "w-full border-b border-gray-200 h-16 flex items-center justify-between px-6" }
const _hoisted_4 = { class: "flex-1 overflow-auto" }
const _hoisted_5 = { class: "w-full max-w-4xl mx-auto px-2 py-6 flex items-start sm:items-center justify-center min-h-full" }
const _hoisted_6 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ZendeskHelpWidget = _resolveComponent("ZendeskHelpWidget")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.wrapper), {
      class: "min-h-screen relative",
      style: {"background":"#1269c1"},
      onSnapImpersonationModeChanged: _ctx.onImpersonationModeChange
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("snap-logo", {
                  product: "snap-logo-mobile",
                  white: "false",
                  class: "cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'home' })), ["prevent"]))
                }),
                _createElementVNode("snap-button", {
                  size: "sm",
                  icon: "logout-solid",
                  variant: "tertiary",
                  "icon-position": "undefined",
                  disabled: "false",
                  "on-submit-btn": "false",
                  "icon-only-mobile": "false",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.exitButtonClick && _ctx.exitButtonClick(...args)))
                }, " Exit ")
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ])
              ])
            ])
          ])
        ])
      ]),
      _: 3
    }, 40, ["onSnapImpersonationModeChanged"])), [
      [_vShow, _ctx.isAuthorized]
    ]),
    _createVNode(_component_ZendeskHelpWidget)
  ], 64))
}