<template>
  <Teleport to="body">
    <Transition name="dialog">
      <div
        role="dialog"
        v-if="isOpen"
        @click.prevent="!isModal && close()"
        class="dialog fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-2"
      >
        <div
          class="inner flex flex-col bg-white rounded-xl h-full md:h-auto w-full md:w-1/2 lg:w-1/3 xl:w-1/4 overflow-hidden"
          @click.stop
        >
          <header
            class="flex flex-row items-center px-5 py-2"
            v-if="title || isBack || !isModal"
          >
            <div class="flex-1 pr-2" v-if="isBack">
              <snap-link
                text="Back"
                size="base"
                variant="default"
                icon="arrow-left-solid"
                icon-position="left"
                @click="$emit('back')"
              ></snap-link>
            </div>

            <div
              class="flex-1 pr-2 text-base font-bold text-gray-800"
              v-if="!isBack"
            >
              {{ title }}
            </div>

            <snap-close-button
              color="gray"
              @click="close"
              v-if="!isModal && !isBack"
            ></snap-close-button>
          </header>

          <section class="flex-1 pt-2 overflow-y-auto px-5 py-2">
            <slot :close="close" :state="state"></slot>
          </section>

          <footer class="px-5 py-2 flex md:justify-end">
            <slot name="actions" :close="close" :state="state"></slot>
          </footer>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts">
import { Options } from "vue-class-component";

import Modal from "@/core/Modal";

@Options({
  props: { isModal: Boolean, isBack: Boolean },
  emits: ["back"],
})
export default class Dialog extends Modal {
  isModal = false;
  isBack = false;
}
</script>

<style scoped lang="scss">
.dialog-enter-active,
.dialog-leave-active {
  transition: background-color 150ms ease;

  & .inner {
    transition: transform 500ms ease-in-out;
  }
}

.dialog-enter-from,
.dialog-leave-to {
  background-color: rgba(107, 114, 128, 0);

  & .inner {
    transform: translateY(-100%);
  }
}

.dialog-enter-to,
.dialog-leave-from {
  background-color: rgba(107, 114, 128, 0.75);

  & .inner {
    transform: translateY(0);
  }
}

//.inner {
//  max-width: 90%;
//}

.dialog {
  z-index: 10005;
}

header {
  border-bottom: 1px solid #e2e8f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

footer {
  border-top: 1px solid #e2e8f0;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.05);

  &:empty {
    display: none;
  }

  :slotted(snap-button) {
    flex-grow: 1;

    @screen md {
      flex-grow: 0;
    }
  }

  :slotted(snap-button + snap-button) {
    margin-left: 0.5rem;
  }
}
</style>
