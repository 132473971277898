import type { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { RouteLocationRaw, RouteParamsRaw, RouteRecordName } from "vue-router";

export enum PageContext {
  User = "user",
  Admin = "admin",
  OrgAdmin = "org_admin",
}

export type NavigationTabMenuItem = DropdownMenuItem & {
  value?: RouteRecordName;
  params?: RouteParamsRaw;
  toggle?: string;
  forceNavigation?: boolean;
};

export type BackRoute = {
  text?: string;
  route: RouteLocationRaw;
};
