import { apolloClient } from "@/providers/apollo.provider";

import MessagesChannelUpdateMutation from "@/graphql/messagesChannelUpdate.mutation.graphql";
import MessagesChannelRemoveMembers from "@/graphql/messagesChannelRemoveMembers.mutation.graphql";
import MyModeratedChannels from "@/graphql/messagesMyModeratedChannels.query.graphql";

import {
  ChatChannelUpdateResult,
  MessagesChannelRemoveMembersResult,
} from "@/types/graphql";

export const updateChannel = async (
  id: string,
  channel: {
    name?: string;
    description?: string;
    status?: string;
    isReadOnly?: boolean;
  }
): Promise<ChatChannelUpdateResult> => {
  return apolloClient
    .mutate({
      mutation: MessagesChannelUpdateMutation,
      variables: { id, channel },
    })
    .then(({ data }) => data.messagesChannelUpdate);
};

export const removeChannelMembers = async (
  id: string,
  userIds: [string]
): Promise<MessagesChannelRemoveMembersResult> => {
  return apolloClient
    .mutate({
      mutation: MessagesChannelRemoveMembers,
      variables: { id, userIds },
    })
    .then(({ data }) => data.messagesChannelRemoveMembers);
};

export const myModeratedChannelsCount = async (): Promise<number> => {
  return apolloClient
    .query({
      query: MyModeratedChannels,
    })
    .then(({ data }) => {
      if (data.messagesMyModeratedChannels?.messages) {
        throw new Error(data.messagesMyModeratedChannels.messages);
      }
      return data.messagesMyModeratedChannels.count;
    })
    .catch((e) => {
      console.error(e.message);
      return 0;
    });
};
