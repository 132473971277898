<template>
  <div>
    <form
      class="flex items-start gap-2 my-4"
      v-if="!isWaitingForConfirmation"
      @submit.prevent="requestUpdate()"
      v-keyboard-submit
    >
      <InputField
        class="block flex-1"
        label="Phone"
        name="phoneNumber"
        type="text"
        v-model="phoneNumber"
        :validation="v$.phoneNumber"
      ></InputField>

      <snap-button
        button-type="submit"
        icon-position="left"
        shape="circular"
        size="md"
        variant="primary"
        class="mt-6"
        :disabled="!isPhoneNumberChanged || state$.is('SUBMITTING')"
        >Update
      </snap-button>
    </form>

    <form
      class="my-4"
      v-if="isWaitingForConfirmation"
      @submit.prevent="confirmUpdate()"
      v-keyboard-submit
    >
      <Alert type="warning" v-if="!state$.is('SUBMITTING')">
        We’ve sent a verification code to
        <template #description>{{ phoneNumber }}</template>
      </Alert>

      <div class="flex gap-2 flex-wrap">
        <div>
          <InputCodeField
            label="Phone Verification Code"
            v-model="code"
            :validation="v$.code"
            help-text="Please enter your 6-digit verification code."
          ></InputCodeField>

          <snap-link @click.prevent="requestUpdate(true)"
            >Send New Code
          </snap-link>
        </div>

        <snap-button
          button-type="submit"
          icon-position="left"
          shape="circular"
          size="md"
          variant="primary"
          class="mt-6"
          :disabled="state$.is('SUBMITTING') || code.length < 6"
          >Confirm
        </snap-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Options } from "vue-class-component";
import { mapGetters } from "vuex";

import { Form, Model, Submit } from "@/core/Form";

import type { User } from "@/types/graphql";
import type { FeatureToggle } from "@/store/treatments.module";

import InputField from "@/components/snap-ui/InputField.vue";
import InputCodeField from "@/components/snap-ui/InputCodeField.vue";
import Alert from "@/components/snap-ui/Alert.vue";

import { $notify } from "@/services/notification";
import {
  RequiredRule,
  PhoneNumberRule,
  ValidationCodeRule,
} from "@/util/validator";

import { userPhoneNumberConfirm, userUpdate } from "@/services/user.service";
import { redirectOnComplete } from "@/util/consumer";

@Options({
  components: { Alert, InputField, InputCodeField },
  computed: {
    ...mapGetters(["me", "featureOn"]),
  },
})
export default class PhoneUpdateForm extends Form {
  me?: User;
  featureOn?: FeatureToggle;

  @Model({
    required: RequiredRule("Phone number is required."),
    phone: PhoneNumberRule("Please enter a valid phone number."),
  })
  phoneNumber = "";

  @Model({
    required: RequiredRule("Verification code is required."),
    code: ValidationCodeRule("Verification code is invalid."),
  })
  code = "";

  isWaitingForConfirmation = false;

  mounted() {
    this.phoneNumber = this.me?.phoneNumber || "";
  }

  get isPhoneNumberChanged(): boolean {
    return this.phoneNumber !== this.me?.phoneNumber;
  }

  @Submit({ model: "phoneNumber" })
  async requestUpdate(ignoreChanges?: boolean): Promise<void> {
    if (
      (ignoreChanges || this.isPhoneNumberChanged) &&
      (await this.isPartialValid(["phoneNumber"]))
    ) {
      await userUpdate({ id: this.me?.id, phoneNumber: this.phoneNumber });
      this.isWaitingForConfirmation = true;
    }
  }

  @Submit({ model: "code" })
  async confirmUpdate(): Promise<void> {
    if (await this.isPartialValid(["code"])) {
      await userPhoneNumberConfirm(this.code);
      this.isWaitingForConfirmation = false;
      this.$store.commit("updateUserState", {
        user: { phoneNumber: this.phoneNumber },
      });

      $notify({
        title: "Your mobile number has been updated",
        type: "success",
      });

      await redirectOnComplete();
    }
  }
}
</script>
