import {
  InMemoryCache,
  ApolloClient,
  ApolloLink,
  HttpLink,
  from,
  fromPromise,
} from "@apollo/client";

import { createApolloProvider } from "@vue/apollo-option";
import { onError } from "@apollo/client/link/error";
import { GraphQLError } from "graphql/error/GraphQLError";
import DebounceLink from "apollo-link-debounce";
import { SentryLink } from "apollo-link-sentry";

import router from "@/router";
import store from "@/store";

import { TransportEnum } from "@/types/graphql";

import { fullName } from "@/util/helper";

import { config } from "@/services/config";

const cache = new InMemoryCache({
  typePolicies: {
    UserWithPermissions: {
      fields: {
        fullName: {
          read(_, { readField }) {
            const firstName = readField<string>("firstName");
            const lastName = readField<string>("lastName");

            return fullName(firstName, lastName);
          },
        },
      },
    },

    User: {
      fields: {
        fullName: {
          read(_, { readField }) {
            const firstName = readField<string>("firstName");
            const lastName = readField<string>("lastName");

            return fullName(firstName, lastName);
          },
        },
      },
    },

    UserPublic: {
      fields: {
        fullName: {
          read(_, { readField }) {
            const firstName = readField<string>("firstName");
            const lastName = readField<string>("lastName");

            return fullName(firstName, lastName);
          },
        },
      },
    },
  },
});

const sentryLink = new SentryLink({
  uri: config.GRAPHQL_URI,
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: true,
    includeFetchResult: true,
    includeError: true,
  },
});

const debounceLink = new DebounceLink(100);

const authLink = new ApolloLink((operation, forward) => {
  // operation.setContext(({ headers = {} }) => {
  //   const token = getAccessToken();
  //
  //   return {
  //     headers: {
  //       ...headers,
  //       authorization: token ? `Bearer ${token}` : "",
  //     },
  //   };
  // });

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: config.GRAPHQL_URI,
  credentials: "include",
});

const hasGraphQLError = (
  graphQLErrors: readonly GraphQLError[] = [],
  code: string
): boolean =>
  graphQLErrors.find((error) => error.extensions?.code === code) !== undefined;

export const errorLink = onError(
  ({ /*response,*/ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      if (
        hasGraphQLError(graphQLErrors, "FORBIDDEN") &&
        store.getters.isAuthorized === true
      ) {
        store.commit("clearUserState");

        router.push({ name: "login" });
        return;
      }

      if (hasGraphQLError(graphQLErrors, "NOT_CONFIRMED")) {
        const email = operation.variables.email;
        const availableTransports = graphQLErrors[0]?.extensions
          ?.availableTransport || [TransportEnum.Email];
        const usedTransports = graphQLErrors[0]?.extensions?.transport || [
          {
            recipient: email,
            transport: TransportEnum.Email,
          },
        ];

        store.commit("clearUserState");
        store.commit("updateConfirmationState", {
          email,
          availableTransports,
          usedTransports,
        });

        router.push({ name: "verify" });
        return;
      }

      if (hasGraphQLError(graphQLErrors, "NOT_MIGRATED")) {
        const email = graphQLErrors[0]?.extensions?.email;
        const availableTransports = graphQLErrors[0]?.extensions
          ?.availableTransport || [TransportEnum.Email];
        const usedTransports = graphQLErrors[0]?.extensions?.transport || [
          {
            recipient: email,
            transport: TransportEnum.Email,
          },
        ];
        const code = graphQLErrors[0]?.extensions?.verificationCode;

        store.commit("updateResetPasswordState", {
          email,
          notMigrated: true,
          availableTransports,
          usedTransports,
        });

        router.push({
          name: "reset-password",
          query: code ? { code: code as string } : {},
        });
        return;
      }

      // INFO: https://stackoverflow.com/questions/61327448/how-to-refresh-jwt-token-using-apollo-and-graphql
      // INFO: https://www.apollographql.com/docs/react/data/error-handling/#retrying-operations
      // INFO: https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
      if (
        hasGraphQLError(graphQLErrors, "TOKEN_EXPIRED") &&
        operation.operationName !== "UserRefreshSession"
      ) {
        return fromPromise(
          store.dispatch("userRefreshSession").then(() => true)
        )
          .filter((value) => Boolean(value))
          .flatMap(() => {
            return forward(operation);
          });
      }
    }
  }
);

export const apolloClient = new ApolloClient({
  cache,
  link: from([sentryLink, debounceLink, authLink, errorLink, httpLink]),
  name: "user-directory",
  version: process.env.VUE_APP_VERSION,
});

window.__SNAP_APOLLO_CLIENT__ = apolloClient;

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

// SOURCE: https://stackoverflow.com/a/73923165/9968502
export const evictFromCache = (...names: Array<string>) => {
  names.forEach((name) => cache.evict({ id: "ROOT_QUERY", fieldName: name }));
  cache.gc();
};
