import { Module, GetterTree, ActionTree, MutationTree } from "vuex";

import type {
  UserWithPermissions,
  MutationUserSignupConfirmArgs,
} from "@/types/graphql";
import type { RootState } from "./index";

import {
  login,
  logout,
  me,
  userImpersonate,
  userImpersonateLogout,
  userRefresh,
  userRefreshSession,
  userSignupConfirm,
  userUpdate,
} from "@/services/user.service";
import { fullName } from "@/util/helper";

import { UserAuthState } from "@/types/user";
import { myModeratedChannelsCount } from "@/services/messages.service";

type UserState = {
  user?: UserWithPermissions;
  authState?: UserAuthState;
};

const state: UserState = {
  user: {},
  authState: UserAuthState.Unknown,
};

export type SinglePermissionChecker = (permission: string) => boolean;
export type MultiplePermissionsChecker = (permission: Array<string>) => boolean;

const getters: GetterTree<UserState, RootState> = {
  me: (state) => state.user,
  authState: (state) => state.authState,
  isAuthorized: (state) => state.user?.id !== undefined,
  isSpoofed: (state) =>
    state.user?.parents !== undefined && state.user?.parents !== null,

  hasPermission:
    (state) =>
    (permission: string): boolean =>
      !!state.user?.permissions?.includes(permission),

  hasEveryPermissions:
    (state) =>
    (permissions: Array<string>): boolean =>
      permissions.every((permission) =>
        state.user?.permissions?.includes(permission)
      ),

  hasAnyPermissions:
    (state) =>
    (permissions: Array<string>): boolean =>
      permissions.some((permission) =>
        state.user?.permissions?.includes(permission)
      ),

  hasNoPermissions:
    (state) =>
    (permissions: Array<string>): boolean =>
      permissions.every(
        (permission) => !state.user?.permissions?.includes(permission)
      ),
};

const mutations: MutationTree<UserState> = {
  updateUserState(
    state,
    payload: {
      user: UserWithPermissions;
      userModeratedChannelsCount?: number;
    }
  ) {
    state.user = { ...(state.user || {}), ...payload.user };

    state.user.fullName = fullName(
      state.user.firstName as string | undefined,
      state.user.lastName as string | undefined
    );

    if (state.user.profilePicture) {
      state.user.profilePicture += `?${+new Date()}`;
    }

    if (state.user.hasFamily) {
      state.user.permissions = [
        ...(state.user.permissions || []),
        "computed:has_family",
      ];
    }

    if (state.user.occupation) {
      state.user.permissions = [
        ...(state.user.permissions || []),
        `computed:${state.user.occupation}`,
      ];
    }

    if (payload?.userModeratedChannelsCount) {
      state.user.permissions = [
        ...(state.user.permissions || []),
        `computed:show_orgs_admin`,
      ];
    }

    state.authState = UserAuthState.Authenticated;

    window.dispatchEvent(
      new CustomEvent("SNAP_AUTH_UPDATED", {
        detail: "setAuthData",
      })
    );
  },

  clearUserState(state) {
    state.user = {};
    state.authState = UserAuthState.NonAuthenticated;

    window.dispatchEvent(
      new CustomEvent("SNAP_AUTH_UPDATED", {
        detail: "authFailed",
      })
    );
  },

  updateUserAuthState(state, authState: UserAuthState) {
    state.authState = authState;
  },
};

const actions: ActionTree<UserState, RootState> = {
  async me({ commit, state }) {
    const user = await me();
    const userModeratedChannelsCount = await myModeratedChannelsCount();

    if (state.user?.id !== user?.id) {
      commit("updateUserState", { user, userModeratedChannelsCount });
    } else if (user?.id === undefined) {
      commit("updateUserAuthState", UserAuthState.NonAuthenticated);
    }
  },

  async login({ dispatch }, { email, password, consumer }) {
    const { challenges } = await login(email, password, consumer);
    await dispatch("setChallenges", challenges);

    const hasRequiredChallenges = challenges?.some(
      (challenge) => challenge?.params?.required === true
    );

    if (!hasRequiredChallenges) {
      await dispatch("me");

      await dispatch("destroyTreatments");
      await dispatch("useTreatments");
    }
  },

  async logout({ commit, dispatch }) {
    await logout();

    commit("clearUserState");
    commit("clearConsumerData");
    commit("clearChallenges");

    await dispatch("destroyTreatments");
    await dispatch("useTreatments");
  },

  async userRefresh({ commit }) {
    const user = await userRefresh();
    const userModeratedChannelsCount = await myModeratedChannelsCount();

    commit("updateUserState", { user, userModeratedChannelsCount });
  },

  async userUpdate({ commit }, user: UserWithPermissions) {
    commit("updateUserState", { user: await userUpdate(user) });
  },

  async userRefreshSession() {
    await userRefreshSession();
  },

  async userSignupConfirm({ dispatch }, args: MutationUserSignupConfirmArgs) {
    await userSignupConfirm(args);
    await dispatch("me");
  },

  async userImpersonate({ dispatch }, userId) {
    await userImpersonate(userId);
    await dispatch("me");
  },

  async userImpersonateLogout({ dispatch }) {
    try {
      await userImpersonateLogout();
      await dispatch("me");
    } catch {
      await dispatch("logout");
    }
  },
};

export const user: Module<UserState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
