<template>
  <form @submit.prevent="update()" v-keyboard-submit>
    <InputField
      class="block mb-4"
      label="First Name"
      type="text"
      v-model="firstName"
      :validation="v$.firstName"
    ></InputField>

    <InputField
      class="block mb-4"
      label="Last Name"
      type="text"
      v-model="lastName"
      :validation="v$.lastName"
    ></InputField>

    <LanguageSelect
      class="block mb-4"
      label="Language"
      v-model="language"
    ></LanguageSelect>

    <Select
      v-if="isHsGradYearVisible"
      class="mb-4"
      label="High School Graduation Year"
      placeholder="High School Graduation Year"
      modalTitle="High School Graduation Year"
      v-model="hsGradYear"
      :options="years"
      :validation="v$.hsGradYear"
    ></Select>
  </form>
</template>

<script lang="ts">
import { Options } from "vue-class-component";
import { mapGetters } from "vuex";

import { Form, FormSubmitError, Model, Submit } from "@/core/Form";
import { UserOccupation, UserWithPermissions } from "@/types/graphql";

import InputField from "@/components/snap-ui/InputField.vue";
import InputFileField from "@/components/snap-ui/InputFileField.vue";
import LanguageSelect from "@/components/internal/common/LanguageSelect.vue";

import { $notify } from "@/services/notification";
import {
  EmojiForbiddenRule,
  RegExpRule,
  RequiredRule,
  StringLengthRule,
} from "@/util/validator";
import { redirectOnComplete } from "@/util/consumer";
import Select from "@/components/snap-ui/Select.vue";
import { SelectOptions } from "@/types/snap-ui";
import type { FeatureToggle } from "@/store/treatments.module";

@Options({
  components: { Select, InputField, InputFileField, LanguageSelect },
  computed: {
    ...mapGetters(["me", "featureOn"]),
  },

  watch: {
    error(value: FormSubmitError) {
      if (value)
        $notify({
          title: value.message,
          description: value.description,
          type: "danger",
        });
    },
  },
})
export default class ProfileUpdateForm extends Form {
  me?: UserWithPermissions;
  featureOn?: FeatureToggle;

  @Model({
    required: RequiredRule("First Name is required."),
    length: StringLengthRule(
      "First Name should be 1 to 50 characters long",
      1,
      50
    ),
    emoji: EmojiForbiddenRule(
      "Emojis and special characters are not supported."
    ),
  })
  firstName = "";

  @Model({
    required: RequiredRule("Last Name is required."),
    length: StringLengthRule(
      "Last Name should be 1 to 50 characters long",
      1,
      50
    ),
    emoji: EmojiForbiddenRule(
      "Emojis and special characters are not supported."
    ),
  })
  lastName = "";

  @Model({ regexp: RegExpRule("Please choose the correct year", /\d{4}/g) })
  hsGradYear = "";

  @Model({}) profilePicture = "";
  @Model({}) language = "";

  created(): void {
    this.firstName = this.me?.firstName || "";
    this.lastName = this.me?.lastName || "";
    this.language = this.me?.language || "en";
    this.hsGradYear = this.me?.hsGradYear || "";
  }

  @Submit()
  async update(): Promise<void | boolean> {
    if (this.isFormValid()) {
      await this.$store.dispatch("userUpdate", {
        id: this.me?.id,
        firstName: this.firstName,
        lastName: this.lastName,
        language: this.language,
        profilePicture:
          this.profilePicture !== "" ? this.profilePicture : undefined,
        hsGradYear: this.hsGradYear !== "" ? this.hsGradYear : undefined,
      });

      if (this.hsGradYear !== "") {
        this.$store.commit("updateUserState", {
          user: { hsGradYear: this.hsGradYear },
        });
      }

      $notify({
        title: "Your profile has been updated",
        type: "success",
      });

      this.resetValidation([
        "firstName",
        "lastName",
        "profilePicture",
        "hsGradYear",
      ]);

      await redirectOnComplete();
    } else {
      return false;
    }
  }

  get years(): SelectOptions {
    const YEARS_GAP = 15;
    const CURRENT_YEAR = new Date().getFullYear() + YEARS_GAP;

    return new Array(YEARS_GAP + 1).fill(0).map((_, index) => ({
      name: String(CURRENT_YEAR - index),
      value: String(CURRENT_YEAR - index),
    }));
  }

  get isHsGradYearVisible(): boolean {
    return (
      !!this.featureOn?.("USER-817-show-graduation-year") &&
      this.me?.occupation === UserOccupation.StudentOrParticipant
    );
  }
}
</script>
