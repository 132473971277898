import { NavigationTabMenuItem } from "@/types/ui";

export const routes: Array<NavigationTabMenuItem> = [
  {
    name: "General",
    value: "home",
    text: "General",
  },
  {
    name: "Affiliations",
    value: "profile/affiliations",
    text: "Affiliations",
    toggle: "USER-672-show-users-associations-on-my-profile",
  },
];
