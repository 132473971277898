<template>
  <Alert
    type="info"
    class="mb-6 md:absolute md:top-8 md:inset-x-8 z-10"
    v-if="showInviteParentAlert && !publicFundraiserData"
    >Sign up to help {{ inviteRequester?.firstName }}
    {{ inviteRequester?.lastName }} with their fundraiser.
  </Alert>

  <Alert
    type="info"
    class="mb-6 md:absolute md:top-8 md:inset-x-8 z-10"
    v-if="showInviteParentAlert && publicFundraiserData"
    >Sign up to help {{ inviteRequester?.firstName }}
    {{ inviteRequester?.lastName }} with their
    {{ publicFundraiserData.name }} fundraiser.
  </Alert>

  <Alert
    type="info"
    class="mb-6 md:absolute md:top-8 md:inset-x-8 z-10"
    v-if="showInviteUserAlert"
    >You have an invite to Snap! Mobile. Please sign in to accept it.
  </Alert>

  <Alert
    type="info"
    class="mb-6 md:absolute md:top-8 md:inset-x-8 z-10"
    v-if="showManageAlert"
  >
    <p>
      Your Snap! Manage account has been upgraded to provide enhanced
      functionality and improved security. Please use your existing username and
      password to sign in and finish setup.
    </p>
    <br />

    <p>
      Note to Parents/Guardians: Create an account if you don't already have
      one.
    </p>
    <p v-if="manageRegistrationLink">
      <InlineLink :href="manageRegistrationLink" size="sm"
        >Click Here to Browse Camps.
      </InlineLink>
    </p>
  </Alert>

  <Alert
    type="info"
    class="mb-6 md:absolute md:top-8 md:inset-x-8 z-10"
    v-if="showFanXAlert"
  >
    <p>
      Your FanX Portal account has been upgraded to provide enhanced
      functionality and improved security. Please use your existing username and
      password to sign in and finish setup.
    </p>
  </Alert>

  <PageTitle class="mb-4">Sign in With Your Snap! Mobile Account</PageTitle>

  <p class="mb-4 text-sm" v-if="featureOn?.('USER-888-new-sign-up-view')">
    New to Snap! Mobile?
    <Link :to="{ name: 'signup' }" size="sm">Create Account</Link>
  </p>

  <LoginForm
    :prefill="prefill"
    @reset-password="$router.push({ name: 'reset-password' })"
    @form:error="onFormError"
  ></LoginForm>

  <p
    class="mt-10 text-right md:text-center text-sm"
    v-if="!featureOn?.('USER-888-new-sign-up-view')"
  >
    Don't have a Snap! Mobile account?
    <Link :to="{ name: 'signup' }" size="sm">Create an account.</Link>
  </p>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";

import type { User, PublicFundraiserData } from "@/types/graphql";

import LoginForm from "@/components/LoginForm.vue";
import Link from "@/components/snap-ui/Link.vue";
import Alert from "@/components/snap-ui/Alert.vue";
import PageTitle from "@/components/external/PageTitle.vue";
import InlineLink from "@/components/ui/InlineLink.vue";

import { FeatureToggle } from "@/store/treatments.module";
import PublicFundraiserDataQuery from "@/graphql/publicFundraiserData.query.graphql";
import { manageAlertLink } from "@/util/helper";

@Options<Login>({
  components: { PageTitle, Alert, LoginForm, Link, InlineLink },

  computed: {
    ...mapGetters([
      "consumer",
      "featureOn",
      "inviteEmail",
      "inviteType",
      "inviteRequester",
      "joincode",
      "redirectTo",
    ]),
  },

  apollo: {
    publicFundraiserData: {
      query: PublicFundraiserDataQuery,
      variables() {
        return {
          identifier: this.joincode,
        };
      },
      skip() {
        return !this.joincode;
      },
    },
  },
})
export default class Login extends Vue {
  consumer?: string;
  featureOn?: FeatureToggle;
  inviteEmail?: string;
  inviteType?: string;
  inviteRequester?: User;
  joincode?: string;
  prefill: { email?: string } = {};
  redirectTo?: string;

  publicFundraiserData?: PublicFundraiserData;

  hasError = false;

  get showInviteParentAlert(): boolean {
    if (this.featureOn?.("USER-979-update-signup-data-prefill")) {
      return !this.hasError && this.inviteType === "parent";
    }

    return (
      !this.hasError &&
      !!this.inviteEmail &&
      !!this.inviteRequester &&
      !!this.joincode
    );
  }

  get showInviteUserAlert(): boolean {
    return !this.hasError && !!this.inviteEmail && !this.joincode;
  }

  get showManageAlert(): boolean {
    return this.isManageProductAlert() && !this.isFanxRedirect();
  }

  get showFanXAlert(): boolean {
    return this.isManageProductAlert() && this.isFanxRedirect();
  }

  get manageRegistrationLink(): string | undefined {
    return manageAlertLink(this.consumer, this.redirectTo);
  }

  async mounted() {
    if (this.$route.query.email) {
      this.prefill.email = this.$route.query.email as string;
    }

    if (this.inviteEmail) {
      this.prefill.email = this.inviteEmail;
    }

    await this.$store.dispatch("useTreatments");
  }

  onFormError() {
    this.hasError = true;
  }

  isFanxRedirect(): boolean {
    if (
      this.$route.query.redirectTo &&
      this.$route.query.redirectTo.includes("/fanx-portal")
    ) {
      return true;
    }

    return false;
  }

  isManageProductAlert(): boolean {
    return !this.hasError && this.consumer === "manage";
  }
}
</script>
