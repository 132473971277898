<template>
  <Self v-slot="{ profilePicture, fullName, email }">
    <div class="flex items-center">
      <router-link v-if="isMyOrgsVisible" :to="{ name: 'orgs/list' }">
        <snap-icon
          icon="office-building-settings-solid"
          color="#FFFFFF"
          size="md"
          class="mt-2 mr-4 cursor-pointer"
        ></snap-icon>
      </router-link>

      <snap-app-switcher
        v-if="isAppSwitcherVisible"
        :color.prop="color"
        :key="appSwitcherKey"
        class="pr-1"
      ></snap-app-switcher>

      <div
        v-if="isAppSwitcherVisible || isMyOrgsVisible"
        class="w-px h-6 mr-4 bg-gray-300"
      ></div>

      <div
        @click.prevent="toggleDropdown"
        class="flex items-center cursor-pointer"
        :style="{ color: color || '#000' }"
      >
        <snap-avatar
          :image-src="profilePicture || ''"
          :size="avatarSize"
          class="border rounded-full border-white"
          :notification-side="unconfirmedAffiliationCount > 0 ? 'top' : ''"
          notification-color="#D97706"
        ></snap-avatar>

        <div class="mx-2 text-sm hidden md:block">
          {{ fullName }}
        </div>

        <snap-icon
          class="hidden md:block mt-1"
          :icon="isOpened ? 'chevron-up-solid' : 'chevron-down-solid'"
          size="xs"
        ></snap-icon>
      </div>

      <snap-dropdown
        title="Signed in as"
        :description="email"
        :options.prop="options"
        hide-button="true"
        track-current-selection="false"
        modal-type="drawer"
        @snap-dropdown-item-selected="onMenuItemSelected"
        @snap-dropdown-closed="onDropDownClose"
        ref="dropdown"
      ></snap-dropdown>
    </div>
  </Self>

  <snap-support-modal
    title="Learning Center"
    v-if="isSupportModalOpened"
    @snap-modal-close="isSupportModalOpened = false"
  ></snap-support-modal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";

import { Components } from "@snap-mobile/snap-ui/dist/types/components";

import type { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { mediaQueryState } from "@/util/mediaQuery";

import { SinglePermissionChecker } from "@/store/user.module";
import Self from "@/components/internal/common/Self.vue";

import { subgraphUrl } from "@/util/api";
import {
  User,
  UserOccupation,
  QueryUserUnconfirmedAffiliationsArgs,
  UserAffiliationCounts,
} from "@/types/graphql";
import { FeatureToggle } from "@/store/treatments.module";
import UserUnconfirmedAffiliationsQuery from "@/graphql/userUnconfirmedAffiliations.query.graphql";

@Options({
  components: { Self },
  props: ["color"],

  computed: {
    ...mapGetters(["me", "isSpoofed", "hasPermission", "featureOn"]),
  },

  apollo: {
    userUnconfirmedAffiliations: {
      query: UserUnconfirmedAffiliationsQuery,
      variables(): QueryUserUnconfirmedAffiliationsArgs {
        return {
          userId: this.me.id,
        };
      },
      skip() {
        return !this.isUnconfirmedAffiliationCountRequired;
      },
    },
  },
})
export default class PageHeaderNav extends Vue {
  color?: string;

  me?: User;
  userUnconfirmedAffiliations?: UserAffiliationCounts;
  isSpoofed?: boolean;
  hasPermission?: SinglePermissionChecker;
  featureOn?: FeatureToggle;

  isOpened = false;
  isSupportModalOpened = false;

  get unconfirmedAffiliationCount(): number {
    return this.userUnconfirmedAffiliations?.total || 0;
  }

  get isUnconfirmedAffiliationCountRequired(): boolean {
    return (
      !!this.featureOn?.("USER-672-show-users-associations-on-my-profile") &&
      !!this.me?.id &&
      this.me?.occupation !== UserOccupation.StudentOrParticipant
    );
  }

  get isMyOrgsVisible(): boolean {
    return (
      this.featureOn?.("USER-934-show-staff-roster-page") &&
      this.$store.getters.hasEveryPermissions(["computed:show_orgs_admin"])
    );
  }

  get isAppSwitcherVisible(): boolean {
    if (
      this.me?.occupation === UserOccupation.GroupLeader ||
      (this.me?.apps?.length && this.me?.apps?.length > 1)
    )
      return true;

    if (
      this.me?.occupation === UserOccupation.StudentOrParticipant ||
      this.me?.occupation === UserOccupation.Parent
    )
      return false;

    return true;
  }

  get options(): Array<DropdownMenuItem | undefined> {
    return [
      {
        name: "profile",
        text: "Profile",
        value: this.unconfirmedAffiliationCount
          ? "profile/affiliations"
          : "home",
        badgeText: this.unconfirmedAffiliationCount
          ? `${this.unconfirmedAffiliationCount}`
          : "",
      },

      this.hasPermission?.("user:create")
        ? {
            name: "salesrep_dashboard",
            text: "V1 Salesrep Dashboard",
            value: "",
            href: `${subgraphUrl("raise")}/salesrep/dashboard`,
          }
        : undefined,

      this.hasPermission?.("user:create")
        ? {
            name: "admin_dashboard",
            text: "V1 Admin Dashboard",
            value: "",
            href: `${subgraphUrl("raise")}/admin/dashboard`,
          }
        : undefined,

      this.hasPermission?.("user:create")
        ? {
            name: "warehouse_dashboard",
            text: "V1 Warehouse Dashboard",
            value: "",
            href: `${subgraphUrl("raise")}/warehouse/dashboard`,
          }
        : undefined,

      this.hasPermission?.("user:create")
        ? {
            name: "internal_dashboard",
            text: "V1 Internal Dashboard",
            value: "",
            href: `${subgraphUrl("raise")}/dashboard`,
          }
        : undefined,

      this.isSpoofed
        ? {
            name: "impersonate_logout",
            text: "Impersonate Sign Out",
            value: "link/impersonate_logout",
          }
        : undefined,

      this.featureOn?.("USER-711-show-learning-center-dialog")
        ? {
            name: "learning_center",
            text: "Learning Center",
            value: "Learning Center",
            divider: true,
          }
        : undefined,

      {
        name: "logout",
        text: "Sign Out",
        value: "logout",
      },
    ].filter(Boolean);
  }

  get avatarSize() {
    return mediaQueryState.mobile ? "sm" : "md";
  }

  get appSwitcherKey(): string {
    return this.me?.apps?.join(",") || "";
  }

  async onMenuItemSelected(event: CustomEvent) {
    this.isOpened = false;

    switch (event.detail.name) {
      case "profile":
      case "impersonate_logout":
        await this.$router.push({ name: event.detail.value });
        break;

      case "learning_center":
        this.isSupportModalOpened = true;
        break;

      case "logout":
        await this.$store.dispatch("logout");
        await this.$router.push({ name: "login" });
        break;
    }
  }

  onDropDownClose() {
    this.isOpened = false;
  }

  toggleDropdown() {
    (this.$refs.dropdown as Components.SnapDropdown)?.toggleDropdown();
    this.isOpened = !this.isOpened;
  }
}
</script>

<style scoped lang="scss">
snap-app-switcher {
  margin-right: 0.625rem !important;
}
</style>
