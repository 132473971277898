import { RouteRecordRaw } from "vue-router";

import InternalPage from "@/layouts/InternalPage.vue";
import { PageContext } from "@/types/ui";

export const AdminRoutes: Array<RouteRecordRaw> = [
  {
    path: "/admin/users/bulk",
    name: "admin/users/bulk",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/internal/UsersBulk.vue"),

    meta: {
      title: "Bulk User Create",
      requiresAuth: true,
      hasEveryPermissions: ["user:create", "invite:create"],
      layout: InternalPage,
      context: PageContext.Admin,
    },
  },

  {
    path: "/admin/users/:operation?/:id?",
    name: "admin/users",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/internal/Users.vue"),
    props: true,

    meta: {
      title: "Users",
      requiresAuth: true,
      hasEveryPermissions: ["user:read"],
      layout: InternalPage,
      context: PageContext.Admin,
    },
  },

  {
    path: "/admin/invites",
    name: "admin/invites",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/internal/Invites.vue"),

    meta: {
      title: "Invites",
      requiresAuth: true,
      hasEveryPermissions: ["invite:list"],
      layout: InternalPage,
      context: PageContext.Admin,
    },
  },

  {
    path: "/admin/invites/bulk",
    name: "admin/invites/bulk",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@/views/internal/InvitesBulk.vue"
      ),

    meta: {
      title: "Bulk User Invite",
      requiresAuth: true,
      hasEveryPermissions: ["invite:create"],
      layout: InternalPage,
      context: PageContext.Admin,
    },
  },

  {
    path: "/admin/roles",
    name: "admin/roles",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/internal/Roles.vue"),

    meta: {
      title: "Roles",
      requiresAuth: true,
      hasEveryPermissions: ["role:read"],
      layout: InternalPage,
      context: PageContext.Admin,
    },
  },

  {
    path: "/security/keys",
    name: "security/keys",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/security/ApiKeys.vue"),

    meta: {
      title: "API Keys",
      requiresAuth: true,
      hasEveryPermissions: ["user_keys:access", "permission:read"],
      layout: InternalPage,
      context: PageContext.Admin,
    },
  },
];
