<template>
  <PageContent title="My Profile" :routes="routes">
    <template #icon>
      <Avatar
        :user="me"
        icon="camera-solid"
        @click="drawers.open('updateProfilePictureDialog')"
      ></Avatar>
    </template>

    <template #actions>
      <BackButton></BackButton>
    </template>

    <Alert
      type="warning"
      :key="missingAttributes.length"
      v-if="missingAttributes.length > 0"
    >
      Please fill in the following fields to continue:
      <template #description>
        <ul role="list">
          <li v-for="attr in missingAttributes" :key="attr">
            {{ attrName(attr) }}
          </li>
        </ul>
      </template>
    </Alert>

    <PageSection>
      <section
        class="max-w-3xl mx-auto mb-4 p-4 border border-gray-200 rounded-lg shadow-sm"
      >
        <div class="flex">
          <p class="flex-1 pr-2 text-lg text-gray-800 font-semibold">
            Personal Details
          </p>
          <snap-link
            icon="pencil-solid"
            text="Edit"
            size="sm"
            @click="drawers.open('updateProfileDialog')"
          ></snap-link>
        </div>
        <div class="mt-4 pt-4 border-t border-gray-200">
          <InputReadOnly
            label="Name"
            :value="me?.fullName"
            inline
          ></InputReadOnly>
          <InputReadOnly
            label="Language"
            :value="languageName"
            inline
          ></InputReadOnly>
          <InputReadOnly
            v-if="isHsGradYearVisible && me?.hsGradYear"
            label="High School Graduation Year"
            :value="me?.hsGradYear"
            inline
          ></InputReadOnly>
        </div>
      </section>

      <section
        class="max-w-3xl mx-auto p-4 border border-gray-200 rounded-lg shadow-sm"
      >
        <div class="flex">
          <p class="flex-1 pr-2 text-lg text-gray-800 font-semibold">
            Contact Information
          </p>
          <snap-link
            icon="pencil-solid"
            text="Edit"
            size="sm"
            @click="drawers.open('updateContactsDialog')"
          ></snap-link>
        </div>
        <div class="mt-4 pt-4 border-t border-gray-200">
          <InputReadOnly
            label="Email"
            :value="me?.email"
            inline
          ></InputReadOnly>
          <InputReadOnly
            label="Phone"
            :value="me?.phoneNumber"
            inline
          ></InputReadOnly>
        </div>
      </section>

      <section
        v-if="canEditPassword"
        class="max-w-3xl mx-auto mt-4 p-4 border border-gray-200 rounded-lg shadow-sm"
      >
        <div class="flex">
          <p class="flex-1 pr-2 text-lg text-gray-800 font-semibold">
            Security
          </p>
          <snap-link
            text="Update"
            size="sm"
            @click="drawers.open('updatePasswordDialog')"
          ></snap-link>
        </div>
      </section>
    </PageSection>
  </PageContent>

  <Drawer ref="updateProfilePictureDialog" title="Update Profile Picture">
    <template v-slot="{ close, state }">
      <ProfilePictureUpdateForm
        ref="updateProfilePictureForm"
        @form:success="close"
        @state:normal="state.set('NORMAL')"
        @state:submitting="state.set('SUBMITTING')"
      ></ProfilePictureUpdateForm>
    </template>
    <template v-slot:actions="{ close, state }">
      <snap-button
        button-type="button"
        size="sm"
        variant="tertiary"
        @click="close"
        :disabled="state.is('SUBMITTING')"
        full-width
        >Cancel
      </snap-button>

      <snap-button
        button-type="button"
        size="sm"
        variant="primary"
        @click="
          ($refs.updateProfilePictureForm as ProfilePictureUpdateForm)?.update()
        "
        class="inline-block"
        :disabled="state.is('SUBMITTING')"
        full-width
        >Save
      </snap-button>
    </template>
  </Drawer>

  <Drawer ref="updateProfileDialog" title="Edit Personal Details">
    <template v-slot="{ close, state }">
      <ProfileUpdateForm
        ref="updateProfileForm"
        @form:success="close"
        @state:normal="state.set('NORMAL')"
        @state:submitting="state.set('SUBMITTING')"
      ></ProfileUpdateForm>
    </template>

    <template v-slot:actions="{ close, state }">
      <snap-button
        button-type="button"
        size="sm"
        variant="tertiary"
        @click="close"
        :disabled="state.is('SUBMITTING')"
        full-width
        >Cancel
      </snap-button>

      <snap-button
        button-type="button"
        size="sm"
        variant="primary"
        @click="($refs.updateProfileForm as ProfileUpdateForm)?.update()"
        class="inline-block"
        :disabled="state.is('SUBMITTING')"
        full-width
        >Save
      </snap-button>
    </template>
  </Drawer>

  <Drawer ref="updateContactsDialog" title="Edit Contact Information">
    <EmailUpdateForm
      class="mb-4 border-b border-gray-200"
      v-if="canEditEmail"
    ></EmailUpdateForm>

    <InputReadOnly
      class="my-4 pb-4 border-b border-gray-200"
      v-if="!canEditEmail"
      label="Email"
      :value="me?.email"
      inline
    ></InputReadOnly>

    <PhoneUpdateForm class="my-4"></PhoneUpdateForm>

    <template v-slot:actions="{ close }">
      <snap-button
        button-type="button"
        size="sm"
        variant="tertiary"
        @click="close"
        full-width
        >Cancel
      </snap-button>
    </template>
  </Drawer>

  <Drawer ref="updatePasswordDialog" title="Update Password">
    <template v-slot="{ close, state }">
      <PasswordUpdateForm
        ref="updatePasswordForm"
        @form:success="close"
        @state:normal="state.set('NORMAL')"
        @state:submitting="state.set('SUBMITTING')"
      ></PasswordUpdateForm>
    </template>

    <template v-slot:actions="{ close, state }">
      <snap-button
        button-type="button"
        size="sm"
        variant="tertiary"
        @click="close"
        :disabled="state.is('SUBMITTING')"
        full-width
        >Cancel
      </snap-button>

      <snap-button
        button-type="button"
        size="sm"
        variant="primary"
        @click="($refs.updatePasswordForm as PasswordUpdateForm)?.update()"
        class="inline-block"
        :disabled="state.is('SUBMITTING')"
        full-width
        >Save
      </snap-button>
    </template>
  </Drawer>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";

import { Maybe, UserOccupation, UserWithPermissions } from "@/types/graphql";
import type { RequiredType } from "@/store/consumer.module";
import type { FeatureToggle } from "@/store/treatments.module";

import Alert from "@/components/snap-ui/Alert.vue";
import Avatar from "@/components/snap-ui/Avatar.vue";
import BackButton from "@/components/internal/profile/BackButton.vue";
import Dialog from "@/components/ui/Dialog.vue";
import Drawer from "@/components/ui/Drawer.vue";
import EmailUpdateForm from "@/components/internal/profile/EmailUpdateForm.vue";
import InputReadOnly from "@/components/ui/InputReadOnly.vue";
import PageContent from "@/components/internal/common/PageContent.vue";
import PageSection from "@/components/internal/common/PageSection.vue";
import PasswordUpdateForm from "@/components/internal/profile/PasswordUpdateForm.vue";
import PhoneUpdateForm from "@/components/internal/profile/PhoneUpdateForm.vue";
import ProfilePictureUpdateForm from "@/components/internal/profile/ProfilePictureUpdateForm.vue";
import ProfileUpdateForm from "@/components/internal/profile/ProfileUpdateForm.vue";
import UserAssociationCreateForm from "@/components/internal/orgs/UserAssociationCreateForm.vue";

import { isChildEmail } from "@/util/helper";
import { isInternalEmail } from "@/util/provider";
import { routes } from "@/views/profile/navigation";
import { DrawerManager, useDrawers } from "@/util/drawers";

import { languageName } from "@/services/languages";

const RequiredName: Record<RequiredType, string> = {
  firstName: "First Name",
  lastName: "Last Name",
  phoneNumber: "Mobile Number",
};

const isParticipant = (
  occupation?: UserOccupation | Maybe<UserOccupation>
): boolean => {
  return !occupation || occupation === UserOccupation.StudentOrParticipant;
};

@Options({
  components: {
    Alert,
    Avatar,
    BackButton,
    Dialog,
    Drawer,
    EmailUpdateForm,
    InputReadOnly,
    PageContent,
    PageSection,
    PasswordUpdateForm,
    PhoneUpdateForm,
    ProfilePictureUpdateForm,
    ProfileUpdateForm,
    UserAssociationCreateForm,
  },
  computed: {
    ...mapGetters(["me", "requiredAttributes", "featureOn"]),
  },
})
export default class Profile extends Vue {
  me?: UserWithPermissions;
  requiredAttributes?: Array<RequiredType>;
  featureOn?: FeatureToggle;

  drawers!: DrawerManager;

  get routes() {
    return routes.filter(
      (tab) =>
        tab.value !== "profile/affiliations" ||
        !isParticipant(this.me?.occupation)
    );
  }

  get canEditEmail(): boolean {
    return !(
      (!!this.featureOn?.("USER-381-not-allow-to-edit-child-email") &&
        isChildEmail(this.me?.email as string)) ||
      isInternalEmail(this.me?.email as string)
    );
  }

  get canEditPassword(): boolean {
    return !isInternalEmail(this.me?.email as string);
  }

  get missingAttributes() {
    return (this.requiredAttributes || []).filter(
      (attr: RequiredType) => !this.$store.getters.me[attr]
    );
  }

  get languageName(): string {
    return languageName(this.me?.language || "");
  }

  mounted() {
    this.drawers = useDrawers(this, [
      "updateProfilePictureDialog",
      "updateProfileDialog",
      "updateContactsDialog",
      "updatePasswordDialog",
    ]);
  }

  attrName(attr: RequiredType): string {
    return RequiredName[attr] || "";
  }

  get isHsGradYearVisible(): boolean {
    return (
      !!this.featureOn?.("USER-817-show-graduation-year") &&
      this.me?.occupation === UserOccupation.StudentOrParticipant
    );
  }
}
</script>
