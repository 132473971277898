import {
  AffiliationTitle,
  OrgCampaignAffiliation,
  Org,
  OrgAffiliation,
  ProgramActivity,
  ProgramLeadership,
  ProgramWithTitle,
  TeamAge,
  TeamGender,
  TeamGroupType,
  TeamLeadership,
  TeamTier,
  TeamTitle,
  TeamWithTitle,
  UserAffiliation,
} from "@/types/graphql";
import { normalizeEnum } from "@/util/helper";
import { CombinedAffiliation, ProgramLeader, TeamLeader } from "@/types/orgs";

export type OrgAffiliationListQueryResult = {
  userAffiliations?: Array<OrgAffiliation>;
  userTeamLeadership?: Array<TeamLeadership>;
  userImpliedAffiliations?: Array<OrgCampaignAffiliation>;
  userActivityLeadership?: Array<ProgramLeadership>;
};

export const AFFILIATION_TITLES: Record<AffiliationTitle, string> =
  normalizeEnum<AffiliationTitle>(AffiliationTitle);

export const TEAM_TITLES: Record<TeamTitle, string> =
  normalizeEnum<TeamTitle>(TeamTitle);

export const PROGRAM_ACTIVITY: Record<ProgramActivity, string> =
  normalizeEnum<ProgramActivity>(ProgramActivity);

export const TEAM_GENDER: Record<TeamGender, string> = {
  [TeamGender.Coed]: "Coed",
  [TeamGender.Female]: "Girls",
  [TeamGender.Male]: "Boys",
};

export const TEAM_AGE: Record<TeamAge, string> =
  normalizeEnum<TeamAge>(TeamAge);

export const TEAM_TIER: Record<TeamTier, string> =
  normalizeEnum<TeamTier>(TeamTier);

export const TEAM_GROUP_TYPE: Record<TeamGroupType, string> =
  normalizeEnum<TeamGroupType>(TeamGroupType);

export const orgAddress = (org: Org): string => {
  return `${org.fields?.city}, ${org.fields?.state_code} ${org.fields?.zip_code}`;
};

export const toEnum = (value: string): string => {
  return value.toUpperCase().replace(" ", "_");
};

export const affiliationTitle = (affiliation: UserAffiliation): string =>
  affiliation.title === AffiliationTitle.Other
    ? (affiliation.description as string)
    : AFFILIATION_TITLES[affiliation.title];

export const composeTeamAffiliations = (
  teams: Array<TeamWithTitle>
): Array<TeamLeader> => {
  // Create a flat list of team + title items
  return teams.reduce((acc, team) => {
    return [
      ...acc,
      ...team.titles.map((title) => ({
        team: team.team,
        title: title,
      })),
    ];
  }, [] as Array<TeamLeader>);
};

export const composeActivityAffiliations = (
  programs: Array<ProgramWithTitle> = [],
  teams: Array<TeamLeader> = []
): Array<ProgramLeader> => {
  return (
    programs
      // Create a flat list of program + title items
      .reduce((acc, program) => {
        return [
          ...acc,
          ...program.titles.map((title) => ({
            program: program.program,
            title: title,
          })),
        ];
      }, [] as Array<ProgramLeader>)
      // Remove program affiliation if there is team affiliation with the same title
      .filter(
        (program) =>
          !teams.find(
            (team) =>
              team.team.parent?.id === program.program.id &&
              team.title.title === program.title.title
          )
      )
  );
};

export const composeAffiliationListQueryResult = (
  orgAffiliationList?: OrgAffiliationListQueryResult
): Array<CombinedAffiliation> => {
  let combined: Array<CombinedAffiliation> =
    orgAffiliationList?.userAffiliations?.map((affiliation) => ({
      ...affiliation,
      teams: [],
      programs: [],
    })) || [];

  orgAffiliationList?.userTeamLeadership?.forEach((team) => {
    const index = combined.findIndex(
      (affiliation) => affiliation.org.id === team.org.id
    );

    if (index >= 0) {
      combined[index].teams = [...(combined[index].teams || []), ...team.teams];
    } else {
      combined = [
        ...combined,
        {
          org: team.org,
          affiliations: [],
          teams: team.teams,
          programs: [],
        },
      ];
    }
  });

  orgAffiliationList?.userActivityLeadership?.forEach((program) => {
    const index = combined.findIndex(
      (affiliation) => affiliation.org.id === program.org.id
    );

    if (index >= 0) {
      combined[index].programs = [
        ...(combined[index].programs || []),
        ...program.programs,
      ];
    } else {
      combined = [
        ...combined,
        {
          org: program.org,
          affiliations: [],
          teams: [],
          programs: program.programs,
        },
      ];
    }
  });

  return combined.sort((a1, a2) =>
    (a2.org.name as string) > (a1.org.name as string) ? -1 : 1
  );
};

export const isAffiliationConfirmed = (
  affiliation: CombinedAffiliation
): boolean => {
  const affiliations = affiliation.affiliations || [];
  const teams = composeTeamAffiliations(affiliation.teams);
  const programs = composeActivityAffiliations(affiliation.programs, teams);

  return (
    affiliations.every((a) => a.isConfirmed) &&
    teams.every((team) => team.title.isConfirmed) &&
    programs.every((program) => program.title.isConfirmed)
  );
};
