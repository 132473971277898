<template>
  <snap-tabs
    :type="type || 'primary'"
    :options="options"
    @snap-tabs-item-selected="onItemSelected($event.detail)"
    v-if="options.length > 0"
  ></snap-tabs>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";

import { NavigationTabMenuItem } from "@/types/ui";
import type { FeatureToggle } from "@/store/treatments.module";

type TabMenuSelectedItem = {
  index: number;
  name: string;
};

@Options({
  props: {
    type: String,
    routes: Array<NavigationTabMenuItem>,
  },
  computed: {
    ...mapGetters(["featureOn"]),
  },
  emits: ["tab:selected"],
})
export default class NavigationTabs extends Vue {
  type?: string;
  routes?: Array<NavigationTabMenuItem>;

  featureOn?: FeatureToggle;

  get options(): Array<NavigationTabMenuItem> {
    return (this.routes || [])
      .filter((route) => !route.toggle || this.featureOn?.(route.toggle))
      .map((item) => ({
        ...item,
        selected: item.value === this.$route.name,
      }));
  }

  onItemSelected(selected: TabMenuSelectedItem) {
    const item = this.routes?.[selected.index];
    if (item) {
      this.$emit("tab:selected", item.value);

      if (item.forceNavigation !== false) {
        this.$router.push({ name: item.value, params: item.params || {} });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.snap-tabs.snap-tabs--primary {
  overflow-x: hidden;
  scrollbar-width: thin;
}
</style>
